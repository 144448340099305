import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { Http, HttpModule } from "@angular/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { rootRouterConfig } from "./app.routes";
import { AppComponent } from "./app.component";
import { DemoComponent } from "./demo/demo.component";
import { BlogComponent } from "./blog/blog.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
  declarations: [AppComponent, DemoComponent, BlogComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    HttpModule,

    BrowserAnimationsModule,
    FontAwesomeModule,
    SharedModule,
    RouterModule.forRoot(rootRouterConfig, {
      useHash: false,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled"
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
